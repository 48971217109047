import { PERMISSIONS } from '@bits-app/voggtpit-shared';

import { useOwnUser } from '@/context/own-user.context';

import { NavigationItem } from '../../../elements/Navigation';

export const useGetNavigationItemsForOwnUser = () => {
  const { ownUser, hasPermissions } = useOwnUser();

  const routes: NavigationItem[] = [];

  if (!ownUser) {
    return routes;
  }

  const availableHomeRoute = ALL_PATH.home.filter((route) => hasPermissions(route.permissions));
  if (availableHomeRoute.length) {
    routes.push({ label: 'Data', route: '/', children: availableHomeRoute });
  }

  if (hasPermissions(PERMISSIONS.PAYOUT.READ_PAYOUT)) {
    routes.push({
      label: 'Payout',
      route: '/',
      children: [{ label: 'Order Cancel Requests', route: '/seller-cancel-requests/eur/at' }],
    });
  }

  const availableMarketingPath = ALL_PATH.marketing.filter((route) =>
    route.permissions ? hasPermissions(route.permissions) : true,
  );
  if (availableMarketingPath.length) {
    routes.push({ label: 'Marketing', route: '/', children: availableMarketingPath });
  }

  const availablePlatformRoute = ALL_PATH.platform.filter((route) =>
    hasPermissions(route.permissions),
  );
  if (availablePlatformRoute.length) {
    routes.push({ label: 'Platform Settings', route: '/', children: availablePlatformRoute });
  }

  return routes;
};

const ALL_PATH = {
  home: [
    {
      label: 'Users',
      route: '/user',
      permissions: PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_USER,
    },
    {
      label: 'Shows',
      route: '/show',
      permissions: PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_SHOW,
    },
    {
      label: 'Products',
      route: '/product',
      permissions: PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_PRODUCT,
    },
    {
      label: 'Shipping Addresses',
      route: '/shipping-address',
      permissions: PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_SHIPPING_ADDRESS,
    },
    {
      label: 'Channels',
      route: '/channel',
      permissions: PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_CHANNEL,
    },
    {
      label: 'Orders',
      route: '/order',
      permissions: PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_ORDER,
    },
    {
      label: 'Shipments',
      route: '/shipment',
      permissions: PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_SHIPMENT,
    },
    {
      label: 'Bulk Update',
      route: '/order-items-mass-update/default',
      permissions: PERMISSIONS.ORDER_ITEM_MASS_UPDATE.READ_ORDER_ITEM_MASS_UPDATE,
    },
    { label: 'Refunds', route: '/refund', permissions: PERMISSIONS.REFUND.WRITE_REFUND },
    {
      label: 'User by deviceId',
      route: '/user-by-device-id',
      permissions: PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_USER,
    },
  ],
  marketing: [
    {
      label: 'Promotions',
      route: '/promotions/eur',
      permissions: PERMISSIONS.PROMOTION.READ_PROMOTION,
    },
    {
      label: 'Partner Programs',
      route: '/partner-program',
      permissions: PERMISSIONS.PARTNER_PROGRAM.READ_PARTNER_PROGRAM,
    },
    {
      label: 'Commercial Events',
      route: '/commercial-event',
      permissions: PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_COMMERCIAL_EVENT,
    },
    { label: 'Market Pages', route: '/market-pages' },
    {
      label: 'Featured Show Application',
      route: 'featured-show-application/to-review',
      permissions: PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_FEATURED_SHOW_APPLICATION,
    },
    {
      label: 'Premier Show Application',
      route: 'premier-show-application/to-review',
      permissions: PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_FEATURED_SHOW_APPLICATION,
    },
    {
      label: 'Commercial Event Application',
      route: 'commercial-event-application/to-review',
      permissions:
        PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_COMMERCIAL_EVENT_APPLICATION,
    },
  ],
  platform: [
    {
      label: 'Country',
      route: 'country',
      permissions: PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_COUNTRY,
    },
    {
      label: 'Category',
      route: 'category',
      permissions: PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_CATEGORY,
    },
    {
      label: 'Languages',
      route: 'language',
      permissions: PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_LANGUAGE,
    },
    {
      label: 'Badges',
      route: 'badge',
      permissions: PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_BADGE,
    },
    {
      label: 'Blocked Shipping Addresses',
      route: 'blocked-shipping-address',
      permissions: PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_BLOCKED_SHIPPING_ADDRESS,
    },
    {
      label: 'Shipping Fee Config',
      route: 'shipping-fee-config',
      permissions: PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_SHIPPING_FEE_CONFIG,
    },
    {
      label: 'Seller News',
      route: 'seller-news',
      permissions: PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_SELLER_NEWS,
    },
    {
      label: 'Emotes',
      route: 'emote',
      permissions: PERMISSIONS.DATABASE_EXPLORER.READ_DATABASE_EXPLORER_EMOTE,
    },
    {
      label: 'Sentinel',
      route: 'sentinel',
      permissions: PERMISSIONS.SENTINEL.WRITE_SENTINEL_ADD_USER_TO_FRAUD_CASE,
    },
    {
      label: 'Follow campaigns',
      route: 'auto-follow-user-campaign/active',
      permissions: PERMISSIONS.AUTO_FOLLOW_USER_CAMPAIGN.READ_AUTO_FOLLOW_USER_CAMPAIGN,
    },
    {
      label: 'Data recommendation',
      route: 'feed-recommendation',
      permissions: PERMISSIONS.FEED_RECOMMENDATION.READ_FEED_RECOMMENDATION,
    },
    {
      label: 'Mass import',
      route: 'mass-import',
      permissions: PERMISSIONS.MASS_IMPORT.WRITE_MASS_IMPORT,
    },
  ],
};
