import { Schema } from '@bits-app/voggtpit-shared';
import { Box, Paper, Button, Typography } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SanctionDynamicControl } from '../form/SanctionDynamicControl';
import { SanctionFormType } from '../hooks/use-create-sanction-form';

type SanctionFormProps = {
  schemas: Schema<SanctionFormType>[];
  header?: { title: string };
  onUpsert: (data: SanctionFormType) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  methods: any;
  suggestedSanction: string | null;
};

export const SanctionForm = ({
  schemas,
  methods,
  onUpsert,
  suggestedSanction,
}: SanctionFormProps) => {
  const { t } = useTranslation();
  const formValues = methods.watch();

  const onSubmit = (data: SanctionFormType) => {
    onUpsert(data);
  };

  return (
    <FormProvider {...methods}>
      <Box sx={{ p: 2 }}>
        {suggestedSanction && (
          <div>
            <Typography fontWeight="bold">Suggested sanction:</Typography>
            <Typography color="red">{suggestedSanction}</Typography>
          </div>
        )}
        <Paper sx={{ p: 2 }}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {schemas.map((category) => (
              <Box key={category.categoryLabel} sx={{ mb: 3 }}>
                {category.schema.map((field) => (
                  <Box key={String(field.field)} sx={{ mb: 2 }}>
                    <SanctionDynamicControl
                      inputType={field.inputType}
                      field={field.field}
                      label={field.label}
                      options={field.options}
                      value={formValues[field.field]}
                      onChange={(value) => {
                        methods.setValue(field.field, value, {
                          shouldDirty: true,
                          shouldTouch: true,
                        });
                      }}
                    />
                  </Box>
                ))}
              </Box>
            ))}
            <Box sx={{ mt: 2 }}>
              <Button variant="contained" type="submit">
                {t('action.save')}
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>
    </FormProvider>
  );
};
