import { useState } from 'react';

import assert from 'assert';

import { DynamicFieldData } from '@bits-app/voggtpit-shared';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { DynamicControl } from '../../../../../components/upsert-view/DynamicControl';
import { SanctionFormType } from '../hooks/use-create-sanction-form';

export const SanctionDynamicControl = ({
  inputType,
  field,
  label,
  value,
  extraData,
  onChange,
  disabled,
  options = [],
}: DynamicFieldData<SanctionFormType>) => {
  const { setValue, watch } = useFormContext();
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      event.preventDefault();
      const newUserIds = inputValue.trim().split(' ').map(Number);
      const currentValues = (watch(field) || []) as number[];
      setValue(field as string, [...currentValues, ...newUserIds], {
        shouldValidate: true,
      });
      setInputValue('');
    }
  };

  switch (inputType) {
    case 'select':
      return (
        <FormControl fullWidth>
          <InputLabel id={field}>{label}</InputLabel>
          <Select
            labelId={field}
            id={field}
            label={label}
            value={value || ''}
            onChange={(e) => onChange?.(e.target.value)}
            disabled={disabled}
          >
            {options.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );

    case 'multi-text-field':
      return (
        <TextField
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          label={label}
          variant="outlined"
          size="small"
          fullWidth
          helperText={
            watch(field)?.length > 0
              ? `Current IDs: ${watch(field).join(', ')}`
              : 'Press Enter to add Reporter IDs'
          }
        />
      );

    case 'date-with-time':
      let dateValue: Date | null = null;

      if (value instanceof Date) {
        dateValue = value;
      } else if (typeof value === 'string' || typeof value === 'number') {
        dateValue = new Date(value);
      }

      return (
        <TextField
          type="datetime-local"
          fullWidth
          disabled={disabled}
          label={label}
          value={dateValue ? dateValue.toISOString().slice(0, 16) : ''}
          onChange={(e) => onChange?.(e.target.value ? new Date(e.target.value) : null)}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 1,
          }}
        />
      );

    case 'switch':
      try {
        if (value) {
          assert(typeof value === 'boolean');
        }
      } catch (error) {
        console.error(`[DynamicControl]: value should be of type boolean. Was ${typeof value}`);
      }

      return (
        <Grid container item justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography fontWeight="bold">{label}</Typography>
            {extraData?.description && (
              <Typography variant="body2" color="GrayText">
                {extraData.description}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Switch
              checked={Boolean(value)}
              onChange={(e) => onChange?.(e.target.checked)}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      );

    default:
      return (
        <DynamicControl
          inputType={inputType}
          field={field}
          label={label}
          value={value}
          onChange={onChange}
          disabled={disabled}
          options={options}
          extraData={extraData}
        />
      );
  }
};
