import {
  OffenderType,
  OwnUser,
  SellerOffenseName,
  UserOffenseName,
} from '@bits-app/voggtpit-shared';
import { useQuery, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toDate } from 'date-fns';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { authenticatedInstance } from '../../../../../../axios/axios.instance';
import { useSnackbar } from '../../../../../../components/elements/snackbar/use-snackbar';
import { useOwnUser } from '../../../../../../context/own-user.context';
import { QUERIES_KEYS, queryClient } from '../../../../../../queries';
import { parseSuggestion } from '../utils/parse-suggestion';

export type SanctionFormType = {
  id?: number;
  userId: number;
  agentId: string;
  agent: OwnUser;
  offenderType: OffenderType;
  offenseName: UserOffenseName | SellerOffenseName;
  warning: boolean;
  reporterIds: number[];
  reportedAt: Date;
  blockedAuctionUntil: Date | null;
  blockedCommentUntil: Date | null;
  blockedShowUntil: Date | null;
  isHardblocked: boolean;
};

export const useCreateSanctionForm = (userId: number) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const { ownUser } = useOwnUser();

  const methods = useForm<SanctionFormType>({
    defaultValues: {
      userId,
      agentId: ownUser?.id,
      offenseName: UserOffenseName.insult_light,
      offenderType: OffenderType.buyer,
      reportedAt: toDate(Date.now()),
      warning: false,
      reporterIds: [],
      blockedAuctionUntil: null,
      blockedCommentUntil: null,
      blockedShowUntil: null,
      isHardblocked: false,
    },
  });

  const {
    data: schema,
    isLoading: isLoadingSchema,
    error: schemaError,
  } = useQuery({
    queryKey: QUERIES_KEYS.getSanctionSchema(),
    queryFn: async () => {
      const { data } = await authenticatedInstance.get('/sanction/schema');
      return data;
    },
  });

  const createSanctionMutation = useMutation({
    mutationFn: (data: SanctionFormType) => {
      return authenticatedInstance.post('/sanction', {
        ...data,
        userId,
        agentId: ownUser?.id,
        reportedAt: new Date(),
      });
    },
    onSuccess: () => {
      snackbar.success(t('moderation.sanction.create.success'));
      queryClient.invalidateQueries(QUERIES_KEYS.getUserSanctions(userId));
    },
    onError: (error: AxiosError) => {
      const errorMessage = error.response?.data || error.message || 'Unknown error';
      snackbar.error(t('moderation.sanction.create.error', { message: errorMessage }));
    },
  });

  const onCreate = (data: SanctionFormType) => {
    createSanctionMutation.mutate(data);
  };

  const updateFormWithSuggestion = (suggestion: string | null) => {
    if (!suggestion) return;

    const newValues = parseSuggestion(suggestion);
    Object.entries(newValues).forEach(([field, value]) => {
      methods.setValue(field as keyof SanctionFormType, value);
    });
  };

  return {
    methods,
    updateFormWithSuggestion,
    header: {
      title: t('moderation.sanction.create.title'),
    },
    onCreate,
    schema,
    isLoadingSchema,
    isLoading: isLoadingSchema,
    schemaError,
    createSanctionMutation,
  };
};
