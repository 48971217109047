import { SellerOffenseName } from '@bits-app/voggtpit-shared';

import { createAction } from '../const/actions.const';
import { DURATIONS } from '../const/sanction.const';
import { SanctionMatrix } from '../types/types';

export const youngSellerSanctionMatrix: SanctionMatrix<SellerOffenseName> = {
  [SellerOffenseName.forbidden_games]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
  [SellerOffenseName.mystery_show_issues]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
  [SellerOffenseName.canceled_orders]: {
    0: createAction.blockShow(DURATIONS.ONE_WEEK),
    1: createAction.blockShow(DURATIONS.TWO_WEEKS),
    2: createAction.blockShow(DURATIONS.THREE_WEEKS),
  },
  [SellerOffenseName.poor_item_quality]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
  [SellerOffenseName.late_shipping]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
  [SellerOffenseName.unsent_product_or_giveaway]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
  [SellerOffenseName.show_in_wrong_category]: {
    0: createAction.warning(),
    1: createAction.warning(),
    2: createAction.blockShow(DURATIONS.ONE_WEEK),
  },
  [SellerOffenseName.pre_orders]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
  [SellerOffenseName.sales_before_release]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
  [SellerOffenseName.insult_light]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
  [SellerOffenseName.gdpr_breach_contact]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.FOREVER),
    2: createAction.blockShow(DURATIONS.FOREVER),
  },
  [SellerOffenseName.forbidden_products]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
  [SellerOffenseName.only_hits]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
  [SellerOffenseName.shipped_before_official_release]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
} as const;

export const confirmedSellerSanctionMatrix = {
  [SellerOffenseName.counterfeit]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
  [SellerOffenseName.resealed_products]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
  [SellerOffenseName.forbidden_games]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
  [SellerOffenseName.mystery_show_issues]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
  [SellerOffenseName.canceled_orders]: {
    0: createAction.blockShow(DURATIONS.ONE_WEEK),
    1: createAction.blockShow(DURATIONS.TWO_WEEKS),
    2: createAction.blockShow(DURATIONS.THREE_WEEKS),
  },
  [SellerOffenseName.shill_bidding]: {
    0: createAction.blockShow(DURATIONS.TWO_WEEKS),
    1: createAction.blockShow(DURATIONS.TWO_MONTH),
    2: createAction.blockShow(DURATIONS.THREE_MONTHS),
  },
  [SellerOffenseName.poor_item_quality]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
  [SellerOffenseName.late_shipping]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
  [SellerOffenseName.unsent_product_or_giveaway]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
  [SellerOffenseName.show_in_wrong_category]: {
    0: createAction.warning(),
    1: createAction.warning(),
    2: createAction.blockShow(DURATIONS.ONE_WEEK),
  },
  [SellerOffenseName.pre_orders]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
  [SellerOffenseName.sales_before_release]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
  [SellerOffenseName.payment_outside_voggt]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.TWO_WEEKS),
    2: createAction.blockShow(DURATIONS.THREE_WEEKS),
  },
  [SellerOffenseName.insult_light]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
  [SellerOffenseName.insult_severe]: {
    0: createAction.blockShow(DURATIONS.ONE_WEEK),
    1: createAction.blockShow(DURATIONS.TWO_WEEKS),
    2: createAction.blockShow(DURATIONS.THREE_WEEKS),
  },
  [SellerOffenseName.racism_xenophobia_homophobia_misoginy]: {
    0: createAction.blockShow(DURATIONS.TWO_WEEKS),
    1: createAction.blockShow(DURATIONS.FOREVER),
    2: createAction.blockShow(DURATIONS.FOREVER),
  },
  [SellerOffenseName.threats]: {
    0: createAction.blockShow(DURATIONS.TWO_WEEKS),
    1: createAction.blockShow(DURATIONS.FOREVER),
    2: createAction.blockShow(DURATIONS.FOREVER),
  },
  [SellerOffenseName.gdpr_breach_contact]: {
    0: createAction.blockShow(DURATIONS.TWO_WEEKS),
    1: createAction.blockShow(DURATIONS.FOREVER),
    2: createAction.blockShow(DURATIONS.FOREVER),
  },
  [SellerOffenseName.communication_breach_with_user]: {
    0: createAction.blockShow(DURATIONS.TWO_WEEKS),
    1: createAction.blockShow(DURATIONS.TWO_MONTHS),
    2: createAction.blockShow(DURATIONS.THREE_MONTHS),
  },
  [SellerOffenseName.forbidden_products]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
  [SellerOffenseName.only_hits]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
  [SellerOffenseName.shipped_before_official_release]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
  [SellerOffenseName.drugs_or_alcohol_consumption]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
  [SellerOffenseName.competition_promotion]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
  [SellerOffenseName.fake_shipping]: {
    0: createAction.warning(),
    1: createAction.blockShow(DURATIONS.ONE_WEEK),
    2: createAction.blockShow(DURATIONS.TWO_WEEKS),
  },
} as const;
