import {
  FeaturedShowApplicationWithData,
  formatAmountWithCurrency,
  Currency,
} from '@bits-app/voggtpit-shared';

import { formatText } from '@/utils';

import { CellDefinition } from '../../components/elements/DataList';

import { mapFeaturedShowConceptsToDisplay } from './helper';

export const FeaturedShowApplicationWithDataCellDefinition: CellDefinition<FeaturedShowApplicationWithData>[] =
  [
    {
      id: 'sellerInfo.username' as keyof FeaturedShowApplicationWithData,
      label: 'Username',
      type: 'internal-link',
      getLink: (data) => ({
        label: data.sellerInfo.username,
        link: `/user/${data.sellerInfo.id}`,
      }),
    },
    {
      id: 'featuredShowApplication.show.startAt' as keyof FeaturedShowApplicationWithData,
      label: 'Show start at',
      accessorFn: (data) => data.featuredShowApplication.show.startAt.toLocaleString(),
      type: 'date-with-time',
    },
    {
      id: 'sellerInfo.gmvPerShowLast60Days' as keyof FeaturedShowApplicationWithData,
      label: 'GMV per show last 60 days',
      getValue: (data) =>
        formatAmountWithCurrency(data.sellerInfo.gmvPerShowLast60Days, Currency.eur),
    },
    {
      id: 'featuredShowApplication.show.category.name' as keyof FeaturedShowApplicationWithData,
      label: 'Category',
      accessorFn: (data) => formatText(data.featuredShowApplication.show.category.name),
    },
    {
      id: 'featuredShowApplication.concepts' as keyof FeaturedShowApplicationWithData,
      label: 'Concepts',
      getValue: (data) => mapFeaturedShowConceptsToDisplay(data.featuredShowApplication.concepts),
    },
    {
      id: 'featuredShowApplication.showId' as keyof FeaturedShowApplicationWithData,
      label: 'Show id',
      getLink: (data) => ({
        label: data.featuredShowApplication.showId,
        link: `/show/${data.featuredShowApplication.showId}`,
      }),
      type: 'internal-link',
    },
    {
      id: 'featuredShowApplication.createdAt' as keyof FeaturedShowApplicationWithData,
      label: 'Applied at',
      getValue: (data) => data.featuredShowApplication.createdAt.toLocaleString(),
      type: 'date-with-time',
    },
    {
      id: 'featuredShowApplication.show.countryId' as keyof FeaturedShowApplicationWithData,
      label: 'Show country',
      accessorFn: (data) => data.featuredShowApplication.show.countryId,
      enableColumnFilter: true,
      columnFilter: {
        fieldName: 'featuredShowApplication.show.countryId',
        type: 'multi-select',
        options: [
          { label: 'France', value: 'FR' },
          { label: 'Germany', value: 'DE' },
          { label: 'Autriche', value: 'AT' },
          { label: 'Belgium', value: 'BE' },
          { label: 'Netherlands', value: 'NL' },
        ],
      },
    },
    {
      id: 'featuredShowApplication.show.gmvTargetAmount' as keyof FeaturedShowApplicationWithData,
      label: 'GMV Target Amount',
      getValue: (data) =>
        formatAmountWithCurrency(
          data.featuredShowApplication.show.gmvTargetAmount / 100,
          Currency.eur,
        ),
    },
    {
      id: 'featuredShowApplication.status' as keyof FeaturedShowApplicationWithData,
      label: 'Status',
      accessorFn: (data) => data.featuredShowApplication.status,
      type: 'badge',
    },
    {
      id: 'featuredShowApplication.decisionReason' as keyof FeaturedShowApplicationWithData,
      label: 'Decision reason',
      accessorFn: (data) => data.featuredShowApplication.decisionReason ?? '',
    },
    {
      id: 'sellerInfo.firstShowStartedAt' as keyof FeaturedShowApplicationWithData,
      label: 'First show started at',
      getValue: (data) =>
        data.sellerInfo.firstShowStartedAt
          ? data.sellerInfo.firstShowStartedAt.toLocaleString()
          : null,
      type: 'date-with-time',
    },
    {
      id: 'sellerInfo.maxShowGmv' as keyof FeaturedShowApplicationWithData,
      label: 'Max show GMV',
      getValue: (data) => formatAmountWithCurrency(data.sellerInfo.maxShowGmv, Currency.eur),
      type: 'money',
    },
    {
      id: 'sellerInfo.nbFeaturedOrPremierShowsWhereGmvBelowTargetLast60Days' as keyof FeaturedShowApplicationWithData,
      label: 'Featured GMV below target last 60 days',
      getValue: (data) => data.sellerInfo.nbFeaturedOrPremierShowsWhereGmvBelowTargetLast60Days,
    },
    {
      id: 'sellerInfo.nbFeaturedOrPremierShowsWhereGmvAboveTargetLast60Days' as keyof FeaturedShowApplicationWithData,
      label: 'Featured GMV above target last 60 days',
      getValue: (data) => data.sellerInfo.nbFeaturedOrPremierShowsWhereGmvAboveTargetLast60Days,
    },
    {
      id: 'sellerInfo.phoneNumber' as keyof FeaturedShowApplicationWithData,
      label: 'Phone number',
      accessorFn: (data) => String(data.sellerInfo.phoneNumber),
    },
    {
      id: 'sellerInfo.email' as keyof FeaturedShowApplicationWithData,
      label: 'Email',
      accessorFn: (data) => data.sellerInfo.email,
    },
  ];
