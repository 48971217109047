import { SellerConfig, SellerCompany } from '@bits-app/bits-server-data';
import { centsToUnit, formatAmountWithCurrency, SellerFees } from '@bits-app/voggtpit-shared';

import { Information } from '@/components/layouts/PanelDetails';
import { formatPercentage } from '@/utils';

export const panelDetailsSellerConfig: Information<SellerConfig & SellerFees>[] = [
  { value: 'id', label: 'id' },
  {
    value: 'sellerTierId',
    label: 'Seller level',
    format: (data) => data?.sellerTier?.name?.replace('tier', 'level ') ?? data?.sellerTierId ?? '',
  },
  {
    value: 'percentageFee',
    label: 'percentageFee',
    format: (data) => (data?.percentageFee != null ? formatPercentage(data.percentageFee) : ''),
  },
  {
    value: 'fixedFee',
    label: 'fixedFee',
    format: (data) =>
      data?.fixedFee != null
        ? formatAmountWithCurrency(centsToUnit(data.fixedFee), data.sellerCurrency)
        : '',
  },
  {
    value: 'acceptedStudioConditionsOn',
    label: 'acceptedStudioConditionsOn',
    type: 'date-with-time',
  },
  { value: 'registrationNumber', label: 'registrationNumber' },
  { value: 'sellerCurrency', label: 'sellerCurrency', type: 'badge' },
  { value: 'taxNumber', label: 'taxNumber' },
  { value: 'taxIdentificationNumber', label: 'taxIdentificationNumber' },
  { value: 'dac7ValidatedAt', label: 'DAC7 validated at', type: 'date-with-time' },
  { value: 'cityOfBirth', label: 'City of birth' },
  { value: 'sellerType', label: 'Seller type' },
  { value: 'countryOfBirthIso2Code', label: 'Country of birth' },
  { value: 'createdAt', label: 'createdAt', type: 'date-with-time' },
  { value: 'updatedAt', label: 'updatedAt', type: 'date-with-time' },
  { value: 'deletedAt', label: 'deletedAt', type: 'date-with-time' },
];

export const panelDetailsSellerConfigFeaturesFlag: Information<SellerConfig>[] = [
  { value: 'canUseLedger', label: 'Can use Ledger', type: 'boolean' },
  { value: 'canAccessPreAuth', label: 'Can access pre auth', type: 'boolean' },
  {
    value: 'canBeContactedByCustomersForOrderIssues',
    label: 'Can Be Contacted By Customers For Order Issues',
    type: 'boolean',
  },
  { value: 'canAccessSendcloud', label: 'Can access sendcloud', type: 'boolean' },
  { value: 'canAccessWebRTC', label: 'Can access web RTC', type: 'boolean' },
  { value: 'canAddBulkProduct', label: 'Can add bulk product', type: 'boolean' },
  { value: 'canCreateGiveaway', label: 'Can create giveaway', type: 'boolean' },
  { value: 'canCreateInstantBuy', label: 'Can create instant buy', type: 'boolean' },
  { value: 'canCreateWheel', label: 'Can create Wheel', type: 'boolean' },
  { value: 'canCreateShop', label: 'Can create shop', type: 'boolean' },
  { value: 'canCreateShow', label: 'Can create show', type: 'boolean' },
  {
    value: 'canRequestOrderCancellation',
    label: 'Can request order cancellation',
    type: 'boolean',
  },
  { value: 'canStreamAbove720p', label: 'Can stream above 720p', type: 'boolean' },
  { value: 'isPreBidEligible', label: 'Is Prebid eligible', type: 'boolean' },
  { value: 'isMondialRelayAuthorized', label: 'Is mondial relay authorized', type: 'boolean' },
  { value: 'isSuperSeller', label: 'Is super seller', type: 'boolean' },
  {
    value: 'includeShippingFeesForCommissionByDefault',
    label: 'Include shipping fees for commission by default',
    type: 'boolean',
  },
  {
    value: 'canCreatePreOrders',
    label: 'Can create pre orders',
    type: 'boolean',
  },
  {
    value: 'canSkipStripeDebitAgreement',
    label: 'canSkipStripeDebitAgreement',
    type: 'boolean',
  },
  {
    value: 'stripeMissingInfoDueDate',
    label: 'stripeMissingInfoDueDate',
    type: 'date-with-time',
  },
  {
    value: 'stripeAccountChargesEnabled',
    label: 'stripeAccountChargesEnabled',
    type: 'boolean',
  },
  {
    value: 'stripeAccountPayoutsEnabled',
    label: 'stripeAccountPayoutsEnabled',
    type: 'boolean',
  },
  {
    value: 'canAccessInventory',
    label: 'canAccessInventory',
    type: 'boolean',
  },
  {
    value: 'defaultShowBuyerServiceFeeAmount',
    label: 'Default show buyer service fee amount',
    format: (data) =>
      data?.defaultShowBuyerServiceFeeAmount != null
        ? formatAmountWithCurrency(data.defaultShowBuyerServiceFeeAmount / 100, data.sellerCurrency)
        : '',
  },
];

export const panelDetailsSellerCompany: Information<SellerCompany>[] = [
  { value: 'id', label: 'id' },
  {
    value: 'legalName',
    label: 'Legal name',
  },
  { value: 'address', label: 'Address' },
  { value: 'city', label: 'city' },
  { value: 'zipcode', label: 'zipcode' },
  { value: 'state', label: 'state' },
  { value: 'country', label: 'country' },
  { value: 'phone', label: 'phone' },
  { value: 'taxId', label: 'taxId' },
  { value: 'taxIdRegistrar', label: 'taxIdRegistrar' },
  { value: 'lucidId', label: 'lucidId' },
  { value: 'vatId', label: 'vatId' },
  { value: 'vatIdValidInEu', label: 'vatIdValidInEu', type: 'boolean' },
  { value: 'vatIdValidSince', label: 'vatIdValidSince', type: 'date-with-time' },
  { value: 'registrationNumber', label: 'registrationNumber' },
  { value: 'bic', label: 'bic' },
  { value: 'routingNumber', label: 'routingNumber' },
  { value: 'bankAccountNumber', label: 'bankAccountNumber' },
  { value: 'bankName', label: 'bankName' },
  { value: 'createdAt', label: 'Created at', type: 'date-with-time' },
  { value: 'updatedAt', label: 'Updated at', type: 'date-with-time' },
  { value: 'deletedAt', label: 'Deleted at', type: 'date-with-time' },
];
