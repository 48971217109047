import { UserTableData } from '@bits-app/voggtpit-shared';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';
import { getStripeCustomerUrl } from '@/utils';

export const CellUserTableDefinition: CellDefinition<UserTableData>[] = [
  {
    id: 'id',
    label: 'ID',
    type: 'internal-link',
    getLink: (user) => ({ link: `/user/${user?.id}`, label: user.id }),
  },
  { id: 'username', label: 'Username', type: 'string', enableSorting: true },
  { id: 'email', label: 'Email' },
  { id: 'phoneNumber', label: 'Phone Number' },
  {
    id: 'stripeCustomerId',
    label: 'Stripe Customer',
    type: 'external-link',
    getLink: (user) => ({
      link: getStripeCustomerUrl(user.stripeCustomerId),
      label: user.stripeCustomerId,
    }),
  },
  { id: 'isSeller', label: 'Is Seller', type: 'boolean', width: 120 },
  { id: 'accountDeletedAt', label: 'Deleted at', type: 'date-with-time', enableSorting: true },
  { id: 'createdAt', label: 'Created at', type: 'date-with-time', enableSorting: true },
  { id: 'updatedAt', label: 'Updated at', type: 'date-with-time', enableSorting: true },
];
