// We don't want to sort offenses lists because the care team have their own logic in their spreadsheets.
// We integrated their lists as they are now
export enum UserOffenseName {
  insult_light = 'insult_light',
  insult_severe = 'insult_severe',
  harassment = 'harassment',
  threats = 'threats',
  racism_xenophobia_homophobia_misoginy = 'racism_xenophobia_homophobia_misoginy',
  communication_breach_with_seller = 'communication_breach_with_seller',
  referral_abuse = 'referral_abuse',
  multiple_accounts_creation = 'multiple_accounts_creation',
  wrong_shipping_country = 'wrong_shipping_country',
  competition_promotion = 'competition_promotion',
  payment_outside_voggt = 'payment_outside_voggt',
}

export enum SellerOffenseName {
  counterfeit = 'counterfeit',
  resealed_products = 'resealed_products',
  forbidden_games = 'forbidden_games',
  mystery_show_issues = 'mystery_show_issues',
  canceled_orders = 'canceled_orders',
  shill_bidding = 'shill_bidding',
  poor_item_quality = 'poor_item_quality',
  late_shipping = 'late_shipping',
  unsent_product_or_giveaway = 'unsent_product_or_giveaway',
  show_in_wrong_category = 'show_in_wrong_category',
  minor_seller = 'minor_seller',
  pre_orders = 'pre_orders',
  sales_before_release = 'sales_before_release',
  payment_outside_voggt = 'payment_outside_voggt',
  insult_light = 'insult_light',
  insult_severe = 'insult_severe',
  racism_xenophobia_homophobia_misoginy = 'racism_xenophobia_homophobia_misoginy',
  threats = 'threats',
  gdpr_breach_contact = 'gdpr_breach_contact',
  gdpr_breach_threats = 'gdpr_breach_threats',
  communication_breach_with_user = 'communication_breach_with_user',
  forbidden_products = 'forbidden_products',
  only_hits = 'only_hits',
  missing_products = 'missing_products',
  shipped_before_official_release = 'shipped_before_official_release',
  drugs_or_alcohol_consumption = 'drugs_or_alcohol_consumption',
  competition_promotion = 'competition_promotion',
  fake_shipping = 'fake_shipping',
}

export enum UserSanctionType {
  warning = 'warning',
  auction_block = 'auction_block',
  comment_block = 'comment_block',
  hard_block = 'hard_block',
}

export enum SellerSanctionType {
  warning = 'warning',
  blocked_auction_until = 'blocked_auction_until',
  blocked_comment_until = 'blocked_comment_until',
  blocked_show_until = 'blocked_show_until',
  is_hardblocked = 'is_hardblocked',
}

export enum OffenderType {
  buyer = 'buyer',
  seller = 'seller',
}

export type SanctionType = {
  id: string;
  userId: number;
  agentId: string;
  offenderType: OffenderType;
  offenseName: UserOffenseName | SellerOffenseName;
  warning: boolean;
  reportedAt: Date;
  reporterIds?: number[];
  blockedAuctionUntil: Date | null;
  blockedCommentUntil: Date | null;
  blockedShowUntil: Date | null;
  isHardblocked: boolean;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
};

export const AllOffenseNames = {
  ...UserOffenseName,
  ...SellerOffenseName,
} as const;

export type AllOffenseNamesType = UserOffenseName | SellerOffenseName;
