import {
  CommercialEventApplicationDecisionReason,
  FeaturedShowApplicationDecisionReason,
  FeaturedShowConcept,
} from '@bits-app/bits-server-data';

import { formatText } from '@/utils';

const POSITIVE_DECISION = [
  FeaturedShowApplicationDecisionReason.great_items,
  CommercialEventApplicationDecisionReason.great_items,
  FeaturedShowApplicationDecisionReason.great_track_record,
  CommercialEventApplicationDecisionReason.great_track_record,
  FeaturedShowApplicationDecisionReason.loyal_seller,
  CommercialEventApplicationDecisionReason.loyal_seller,
];

const NEGATIVE_DECISION = [
  FeaturedShowApplicationDecisionReason.full_calendar,
  CommercialEventApplicationDecisionReason.full_calendar,
  FeaturedShowApplicationDecisionReason.not_enough_items,
  CommercialEventApplicationDecisionReason.not_enough_items,
  FeaturedShowApplicationDecisionReason.not_experienced_enough,
  CommercialEventApplicationDecisionReason.not_experienced_enough,
  FeaturedShowApplicationDecisionReason.not_qualitative_enough,
  CommercialEventApplicationDecisionReason.not_qualitative_enough,
  FeaturedShowApplicationDecisionReason.non_official_products,
  CommercialEventApplicationDecisionReason.non_official_products,
  FeaturedShowApplicationDecisionReason.not_vintage_enough,
  CommercialEventApplicationDecisionReason.not_vintage_enough,
  FeaturedShowApplicationDecisionReason.not_enough_low_starting_prices,
  CommercialEventApplicationDecisionReason.not_enough_low_starting_prices,
  FeaturedShowApplicationDecisionReason.no_mystery_boxes,
  CommercialEventApplicationDecisionReason.no_mystery_boxes,
  FeaturedShowApplicationDecisionReason.outside_release_dates,
  CommercialEventApplicationDecisionReason.outside_release_dates,
  FeaturedShowApplicationDecisionReason.targets_not_reached,
  CommercialEventApplicationDecisionReason.targets_not_reached,
];

export const keepOnlyValidReasonBasedOnDecision = (decision: 'approved' | 'refused') => {
  return ([key]: [key: string, value: string]) => {
    if (!decision) {
      return false;
    }
    if (decision === 'approved') {
      return POSITIVE_DECISION.includes(
        key as FeaturedShowApplicationDecisionReason | CommercialEventApplicationDecisionReason,
      );
    }
    return NEGATIVE_DECISION.includes(
      key as FeaturedShowApplicationDecisionReason | CommercialEventApplicationDecisionReason,
    );
  };
};

export function mapFeaturedShowConceptsToDisplay(concepts: FeaturedShowConcept[]) {
  return concepts
    .map((concept) => {
      return formatText(concept.name);
    })
    .join(', ');
}
