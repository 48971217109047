import { UserOffenseName } from '@bits-app/voggtpit-shared';

import { createAction } from '../const/actions.const';
import { DURATIONS } from '../const/sanction.const';
import { SanctionMatrix } from '../types/types';

export const youngBuyerSanctionMatrix: SanctionMatrix<UserOffenseName> = {
  [UserOffenseName.insult_light]: {
    0: createAction.blockChat(DURATIONS.ONE_DAY),
    1: createAction.blockChat(DURATIONS.THREE_DAYS),
    2: createAction.blockChat(DURATIONS.FIVE_DAYS),
  },
  [UserOffenseName.insult_severe]: {
    0: createAction.blockChat(DURATIONS.TWO_WEEKS),
    1: createAction.blockChat(DURATIONS.TWO_WEEKS),
    2: createAction.blockChat(DURATIONS.TWO_WEEKS),
  },
  [UserOffenseName.harassment]: {
    0: createAction.blockChat(DURATIONS.TWO_WEEKS),
    1: createAction.blockChat(DURATIONS.ONE_MONTH),
    2: createAction.hardblock(),
  },
  [UserOffenseName.communication_breach_with_seller]: {
    0: createAction.blockAuction(DURATIONS.ONE_WEEK),
    1: createAction.blockAuction(DURATIONS.TWO_WEEKS),
    2: createAction.hardblock(),
  },
  [UserOffenseName.wrong_shipping_country]: {
    0: createAction.blockAuction(DURATIONS.ONE_WEEK),
    1: createAction.blockAuction(DURATIONS.TWO_WEEKS),
    2: createAction.blockAuction(DURATIONS.ONE_MONTH),
  },
} as const;

export const confirmedBuyerSanctionMatrix: SanctionMatrix<UserOffenseName> = {
  [UserOffenseName.insult_light]: {
    0: createAction.blockChat(DURATIONS.ONE_DAY),
    1: createAction.blockChat(DURATIONS.THREE_DAYS),
    2: createAction.blockChat(DURATIONS.FIVE_DAYS),
  },
  [UserOffenseName.insult_severe]: {
    0: createAction.blockChat(DURATIONS.THREE_DAYS),
    1: createAction.blockChat(DURATIONS.FIVE_DAYS),
    2: createAction.blockChat(DURATIONS.TWO_WEEKS),
  },
  [UserOffenseName.harassment]: {
    0: createAction.blockChat(DURATIONS.TWO_WEEKS),
    1: createAction.blockChat(DURATIONS.ONE_MONTH),
    2: createAction.hardblock(),
  },
  [UserOffenseName.threats]: {
    0: createAction.blockChat(DURATIONS.TWO_WEEKS),
    1: createAction.blockChat(DURATIONS.ONE_MONTH),
    2: createAction.hardblock(),
  },
  [UserOffenseName.racism_xenophobia_homophobia_misoginy]: {
    0: createAction.blockChat(DURATIONS.TWO_WEEKS),
    1: createAction.hardblock(),
    2: createAction.hardblock(),
  },
  [UserOffenseName.communication_breach_with_seller]: {
    0: createAction.blockAuction(DURATIONS.ONE_WEEK),
    1: createAction.blockAuction(DURATIONS.TWO_WEEKS),
    2: createAction.blockAuction(DURATIONS.ONE_MONTH),
  },
  [UserOffenseName.referral_abuse]: {
    0: createAction.blockAuction(DURATIONS.TWO_WEEKS),
    1: createAction.hardblock(),
    2: createAction.hardblock(),
  },
  [UserOffenseName.wrong_shipping_country]: {
    0: createAction.blockAuction(DURATIONS.ONE_WEEK),
    1: createAction.blockAuction(DURATIONS.TWO_WEEKS),
    2: createAction.blockAuction(DURATIONS.ONE_MONTH),
  },
  [UserOffenseName.competition_promotion]: {
    0: createAction.blockChat(DURATIONS.ONE_DAY),
    1: createAction.blockChat(DURATIONS.THREE_DAYS),
    2: createAction.blockChat(DURATIONS.FIVE_DAYS),
  },
  [UserOffenseName.payment_outside_voggt]: {
    0: createAction.blockChat(DURATIONS.ONE_WEEK),
    1: createAction.blockChat(DURATIONS.TWO_WEEKS),
    2: createAction.hardblock(),
  },
} as const;
