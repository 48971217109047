import { User } from '@bits-app/bits-server-data';

import { Information } from '../../../../../../components/layouts/PanelDetails';

export const panelDetailsModerationUserInfo: Information<
  User & {
    buyerGmv?: number;
    sellerGmv?: number;
    buyerOrdersCount?: number;
    sellerOrdersCount?: number;
    sellerCanCreateShow?: boolean;
  }
>[] = [
  { value: 'createdAt', label: 'createdAt', type: 'date-with-time' },
  { value: 'sellerCreatedAt', label: 'Seller since', type: 'date-with-time' },
  {
    value: 'buyerGmv',
    label: 'GMV Spent',
    format: (data) => (data?.buyerGmv ? `${data.buyerGmv / 100}€` : '-'),
  },
  {
    value: 'buyerOrdersCount',
    label: 'Customer nb orders',
    format: (data) => data?.buyerOrdersCount?.toString() ?? '-',
  },
  {
    value: 'sellerGmv',
    label: 'GMV Generated',
    format: (data) => (data?.sellerGmv ? `${data.sellerGmv / 100}€` : '-'),
  },
  {
    value: 'sellerOrdersCount',
    label: 'Seller nb orders',
    format: (data) => data?.sellerOrdersCount?.toString() ?? '-',
  },
  {
    value: 'sellerCanCreateShow',
    label: 'Can seller create a show',
    format: (data) => (data?.sellerCanCreateShow ? 'Yes' : 'No'),
  },
  {
    value: 'blockedCommentUntil',
    label: 'Comments blocked until',
    type: 'date-with-time',
  },
  {
    value: 'blockedAuctionUntil',
    label: 'Auctions blocked until',
    type: 'date-with-time',
  },
  {
    value: 'isHardBlocked',
    label: 'Hard blocked',
    type: 'badge',
    format: (data) => (data?.isHardBlocked ? 'Yes' : 'No'),
  },
  {
    value: 'isBanned',
    label: 'Banned',
    type: 'badge',
    format: (data) => (data?.isBanned ? 'Yes' : 'No'),
  },
];
