import { addDays, addWeeks, addMonths } from 'date-fns';

import { SanctionFormType } from '../hooks/use-create-sanction-form';

type SuggestionValues = Partial<
  Pick<
    SanctionFormType,
    'warning' | 'blockedAuctionUntil' | 'blockedCommentUntil' | 'blockedShowUntil' | 'isHardblocked'
  >
>;

export const parseSuggestion = (suggestion: string): SuggestionValues => {
  const now = new Date();

  if (suggestion === 'Warning') {
    return { warning: true };
  }

  if (suggestion === 'Hardblock') {
    return { isHardblocked: true };
  }

  // Parse block durations
  const [action, duration] = suggestion.split(' - ');

  switch (action) {
    case 'Block chat':
      return {
        blockedCommentUntil: parseDuration(duration, now),
      };

    case 'Auction block':
      return {
        blockedAuctionUntil: parseDuration(duration, now),
      };

    case 'Show ban':
      if (duration === 'forever') {
        return {
          blockedShowUntil: new Date(2099, 11, 31), // Far future date
        };
      }
      return {
        blockedShowUntil: parseDuration(duration, now),
      };

    default:
      return {};
  }
};

const parseDuration = (duration: string, startDate: Date): Date => {
  const [amount, unit] = duration.split(' ');
  const value = parseInt(amount);

  switch (unit) {
    case 'day':
    case 'days':
      return addDays(startDate, value);
    case 'week':
    case 'weeks':
      return addWeeks(startDate, value);
    case 'month':
    case 'months':
      return addMonths(startDate, value);
    default:
      return startDate;
  }
};
