export enum EasyShippingMethod {
  boxtal = 'boxtal',
  sendcloud = 'sendcloud',
}

export enum FavoriteShippingLabelFormat {
  A4 = 'A4',
  A6 = 'A6',
}

export enum SellerType {
  individual = 'individual', // particulier
  self_employed = 'self_employed', // auto-entrepreneur
  company = 'company', // all professionals except auto-entrepreneurs
}
