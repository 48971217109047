import { SanctionType } from '@bits-app/voggtpit-shared';
import { useQuery } from '@tanstack/react-query';

import { authenticatedInstance } from '@/axios/axios.instance';
import { QUERIES_KEYS } from '@/queries';

export const useUserSanctions = (userId: number) => {
  return useQuery<SanctionType[]>(QUERIES_KEYS.getUserSanctions(userId), async () => {
    const { data } = await authenticatedInstance.get(`/sanction/by-user`, {
      params: { userId },
    });
    return data;
  });
};
