import { UserUsedDevice } from '@bits-app/voggtpit-shared';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';

export const CellUserUsedDeviceDefinition: CellDefinition<UserUsedDevice>[] = [
  {
    id: 'id',
    label: 'ID',
    type: 'string',
  },
  {
    id: 'userId',
    label: 'User ID',
    type: 'internal-link',
    getLink: (device) => ({
      label: device.userId,
      link: `/user/${device.userId}`,
    }),
  },
  {
    id: 'type',
    label: 'Type',
    type: 'badge',
  },
  {
    id: 'deviceId',
    label: 'Device ID',
    type: 'string',
  },
  {
    id: 'lastIngestedAt',
    label: 'Last Ingested',
    type: 'date-with-time',
  },
  {
    id: 'fingerprint',
    label: 'Fingerprint',
    type: 'string',
  },
];
