import { Order, SellerConfig, Show } from '@bits-app/bits-server-data';
import { useQuery } from '@tanstack/react-query';

import { authenticatedInstance } from '@/axios/axios.instance';
import { QUERIES_KEYS } from '@/queries';

export type UserStats = {
  buyerGmv: number;
  sellerGmv: number;
  buyerOrdersCount: number;
  sellerOrdersCount: number;
  sellerCanCreateShow: boolean;
  sellerShowCount: number;
};

export const useUserStats = (userId: number) => {
  const { data: buyerOrders } = useQuery(QUERIES_KEYS.userBuyerOrders(userId), async () => {
    const response = await authenticatedInstance.get('/database-explorer/order', {
      params: { filters: { customerId: userId } },
    });
    return response.data as Order[];
  });

  const { data: sellerOrders } = useQuery(QUERIES_KEYS.userSellerOrders(userId), async () => {
    const response = await authenticatedInstance.get('/database-explorer/order', {
      params: { filters: { sellerId: userId } },
    });
    return response.data as Order[];
  });

  const { data: sellerShows } = useQuery(QUERIES_KEYS.showList(userId), async () => {
    const response = await authenticatedInstance.get('/database-explorer/show', {
      params: { userId },
    });
    return response.data as Show[];
  });

  const { data: sellerConfig } = useQuery(QUERIES_KEYS.userSellerConfigDetail(userId), async () => {
    const response = await authenticatedInstance.get(
      `/database-explorer/seller-config/user/${userId}`,
    );
    return response.data as SellerConfig;
  });

  return useQuery<UserStats>(
    ['userOrdersStats', userId],
    () => ({
      buyerGmv: buyerOrders?.reduce((sum, order) => sum + order.amount, 0) ?? 0,
      sellerGmv: sellerOrders?.reduce((sum, order) => sum + order.amount, 0) ?? 0,
      buyerOrdersCount: buyerOrders?.length ?? 0,
      sellerOrdersCount: sellerOrders?.length ?? 0,
      sellerCanCreateShow: sellerConfig?.canCreateShow ?? false,
      sellerShowCount: sellerShows?.length ?? 0,
    }),
    {
      enabled: !!buyerOrders && !!sellerOrders,
    },
  );
};
