import { SanctionType, SellerOffenseName, UserOffenseName } from '@bits-app/voggtpit-shared';

import { CellDefinition } from '@/components/elements/DataList/datalist-type';

import { SanctionDuration } from '../types/types';

export const CellSanctionDefinition: CellDefinition<SanctionType>[] = [
  {
    id: 'id',
    label: 'ID',
    type: 'string',
  },
  {
    id: 'offenderType',
    label: 'Offender Type',
    type: 'badge',
    getValue: (record) => (record.offenderType === 'buyer' ? 'Buyer' : 'Seller'),
  },
  {
    id: 'offenseName',
    label: 'Offense',
    type: 'badge',
    getValue: (record) => record.offenseName as string,
  },
  {
    id: 'warning',
    label: 'Warning',
    type: 'badge',
    getValue: (record) => (record.warning ? 'Yes' : 'No'),
  },
  {
    id: 'reportedAt',
    label: 'Reported At',
    type: 'date-with-time',
  },
  {
    id: 'blockedAuctionUntil',
    label: 'Blocked Auction Until',
    type: 'date-with-time',
  },
  {
    id: 'blockedCommentUntil',
    label: 'Blocked Comments Until',
    type: 'date-with-time',
  },
  {
    id: 'blockedShowUntil',
    label: 'Blocked Show Until',
    type: 'date-with-time',
  },
  {
    id: 'isHardblocked',
    label: 'Hard Blocked',
    type: 'badge',
    getValue: (record) => (record.isHardblocked ? 'Yes' : 'No'),
  },
  {
    id: 'agentId',
    label: 'Agent ID',
    type: 'string',
  },
  {
    id: 'createdAt',
    label: 'Created At',
    type: 'date-with-time',
  },
];

export const THRESHOLDS = {
  YOUNG_USER_ORDERS: 200,
  YOUNG_SELLER_MIN_SHOWS: 1,
} as const;

export const DURATIONS: Record<string, SanctionDuration> = {
  ONE_DAY: '1 day',
  THREE_DAYS: '3 days',
  FIVE_DAYS: '5 days',
  ONE_WEEK: '1 week',
  TWO_WEEKS: '2 weeks',
  THREE_WEEKS: '3 weeks',
  ONE_MONTH: '1 month',
  TWO_MONTHS: '2 months',
  THREE_MONTHS: '3 months',
  FOREVER: 'forever',
} as const;

// Offenses lists
export const YOUNG_SELLER_DEF_BAN_OFFENSES = [
  SellerOffenseName.counterfeit,
  SellerOffenseName.resealed_products,
  SellerOffenseName.shill_bidding,
  SellerOffenseName.minor_seller,
  SellerOffenseName.payment_outside_voggt,
  SellerOffenseName.insult_severe,
  SellerOffenseName.racism_xenophobia_homophobia_misoginy,
  SellerOffenseName.threats,
  SellerOffenseName.gdpr_breach_threats,
  SellerOffenseName.communication_breach_with_user,
  SellerOffenseName.drugs_or_alcohol_consumption,
  SellerOffenseName.competition_promotion,
  SellerOffenseName.fake_shipping,
];

export const CONFIRMED_SELLER_DEF_BAN_OFFENSES = [
  SellerOffenseName.minor_seller,
  SellerOffenseName.gdpr_breach_threats,
];

export const YOUNG_BUYER_DEF_BAN_OFFENSES = [
  UserOffenseName.threats,
  UserOffenseName.racism_xenophobia_homophobia_misoginy,
  UserOffenseName.referral_abuse,
  UserOffenseName.multiple_accounts_creation,
  UserOffenseName.competition_promotion,
  UserOffenseName.payment_outside_voggt,
];

export const CONFIRMED_BUYER_DEF_BAN_OFFENSES = [UserOffenseName.multiple_accounts_creation];
