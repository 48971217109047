import {
  FeaturedShowApplicationType,
  ParentCategory,
  ShippingAddress,
  User,
} from '@bits-app/bits-server-data';
import { SentinelFraudCase, AvailableEntityForComment } from '@bits-app/voggtpit-shared';

export const QUERIES_KEYS = {
  activeAutoFollowUserCampaignList: () => ['GET', '/active-auto-follow-user-campaigns'],
  activityLogs: (entity: AvailableEntityForComment, entityId: number | string) => [
    'GET',
    `/activity-logs/${entity}/${entityId}`,
  ],
  archivedAutoFollowUserCampaignList: () => ['GET', '/archived-auto-follow-user-campaigns'],
  autoFollowUserCampaignCreationSchema: () => [
    'GET',
    '/auto-follow-user-campaigns-creation-schema',
  ],
  badgesList: () => ['GET', '/database-explorer/badge'],
  badgeRelatedUsersCount: (badgeId: number) => [
    'GET',
    `/database-explorer/badge/${badgeId}/related-users-count`,
  ],
  badgeTranslations: (badgeName: string) => [
    'GET',
    `/database-explorer/badge/${badgeName}/translations`,
  ],
  categoriesList: () => ['GET', '/database-explorer/category'],
  categoryTranslations: (categoryName: string) => [
    'GET',
    `/database-explorer/category/${categoryName}/translations`,
  ],
  commercialEvent: (commercialEventId: number) => [
    'GET',
    `/database-explorer/commercial-event/${commercialEventId}`,
  ],
  commercialEventApplicationList: (status?: string, isShowAlreadyDone?: boolean) => [
    'GET',
    '/database-explorer/commercial-event/related-applications',
    status,
    isShowAlreadyDone,
  ],
  commercialEventList: () => ['GET', '/database-explorer/commercial-event'],
  commercialEventMutation: () => ['GET', 'commercial-event-mutation'],
  commercialEventRelatedProducts: (commercialEventId?: number) => [
    'GET',
    `/database-explorer/commercial-event/${commercialEventId}/related-products`,
  ],
  commercialEventRelatedShows: (commercialEventId?: number) => [
    'GET',
    `/database-explorer/commercial-event/${commercialEventId}/related-shows`,
  ],
  commercialEventsByShowId: (showId: number) => ['commercialEventsByShowId', showId],
  draftBadge: (draftBadgeId: string) => ['GET', `/database-explorer/badge/draft/${draftBadgeId}`],
  emote: (emoteId: number) => ['GET', `/database-explorer/emote/${emoteId}`],
  emotesList: () => ['GET', '/database-explorer/emote'],
  environment: () => ['GET', '/environment'],
  featuredShowApplicationList: (
    applicationType?: FeaturedShowApplicationType,
    status?: string,
    isShowAlreadyDone?: boolean,
  ) => [
    'GET',
    '/database-explorer/featured-show-application/search',
    applicationType,
    status,
    isShowAlreadyDone,
  ],
  getChannel: (channelId: number) => ['GET', `/database-explorer/channel/${channelId}`],
  getCountries: () => ['GET', '/database-explorer/country'],
  getCreationShippingAddressSchema: () => [
    'GET',
    '/database-explorer/shipping-address/creation-schema',
  ],
  getMarketPageSchema: () => ['GET', '/market-page/schema'],
  getSanctionSchema: () => ['GET', '/sanction/schema'],
  getUserSanctions: (userId: number) => ['GET', '/sanction/by-user', userId],
  getShipment: (shipmentId: string) => ['GET', `/database-explorer/shipment/${shipmentId}`],
  getShipmentDetailFromOrderId: (orderId: number) => [
    'GET',
    `/database-explorer/shipment/shipment-from-order/${orderId}`,
  ],
  getShippingAddress: (shippingAddressId: ShippingAddress['id']) => [
    'GET',
    `/database-explorer/shipping-address/${shippingAddressId}`,
  ],
  getZohoShowLink: (showId: number) => ['GET', `/zoho/${showId}/zoho-show-link`],
  getZohoUserLink: (userId: number) => ['GET', `/zoho/${userId}/zoho-user-link`],
  ledgerBalance: (userId: number) => ['GET', `/ledger/balance/${userId}`],
  ledgerDetail: (userId: number) => ['GET', `/ledger/detail/${userId}`],
  ledgerPayoutList: (startAt: number, endAt: number, page: number) => [
    'GET',
    'ledger',
    'payout-list',
    startAt,
    endAt,
    page,
  ],
  ledgerTransactionList: (userId: number, currentPage: number, pageSize: number) => [
    'GET',
    `/ledger/transactions/${userId}`,
    currentPage,
    pageSize,
  ],
  marketPage: (marketPageId: string) => ['GET', `/market-page/${marketPageId}`],
  marketPagesList: () => ['GET', '/market-page'],
  marketPageMutation: () => ['GET', 'market-page-mutation'],
  notificationList: (showId?: number) => ['GET', '/notifications-center', showId],
  notificationMutation: () => ['GET', 'notification-mutation'],
  ordersFromShipment: (shipmentId?: string) => [
    'GET',
    `/database-explorer/shipment/orders-from-shipment/${shipmentId}`,
  ],
  ownUsersList: () => ['GET', '/user'],
  parentCategoriesList: () => ['GET', '/database-explorer/parent-category'],
  paymentMethodByUserId: (userId: number) => ['GET', `/database-explorer/payment-method/${userId}`],
  productList: (userId?: User['id']) => ['GET', '/database-explorer/product', 'user', userId],
  productsFromShipment: (shipmentId?: string) => [
    'GET',
    `/database-explorer/shipment/products-from-shipment/${shipmentId}`,
  ],
  relatedCategoriesList: (parentCategoryId: ParentCategory['id']) => [
    'GET',
    `/database-explorer/category/parent/${parentCategoryId}`,
  ],
  sellerCompanyMutationSchema: () => ['seller-company-mutation-schema'],
  sellerNews: (sellerNewsId: number) => ['GET', `/database-explorer/seller-news/${sellerNewsId}`],
  sellerNewsList: () => ['GET', '/database-explorer/seller-news'],
  sellerNewsMutationSchema: () => ['seller-news-mutation-schema'],
  sentinelDetail: (sentinelId?: SentinelFraudCase['id']) => [
    'GET',
    `/sentinel/detail/${sentinelId}`,
  ],
  sentinelList: () => ['GET', '/sentinel'],
  shipmentRatingList: (sellerId?: number) => [
    'GET',
    '/database-explorer/shipment-rating',
    sellerId,
  ],
  shipmentsFromShippingAddress: (shippingAddressId?: number) => [
    'GET',
    `/database-explorer/shipment/shipments-from-shipping-address/${shippingAddressId}`,
  ],
  shipmentsFromUser: (userId?: number) => [
    'GET',
    `/database-explorer/shipment/shipments-from-user/${userId}`,
  ],
  showAssociatedCommercialEventList: (showId: number) => [
    'GET',
    `/database-explorer/commercial-event/by-show/${showId}`,
  ],
  showDetail: (showId: number) => ['GET', '/database-explorer/show', showId],
  showList: (userId?: User['id']) => ['GET', '/database-explorer/show', 'user', userId],
  showOrders: () => ['GET', '/database-explorer/order'],
  showPlayerInformation: (showId: number) => ['GET', '/database-explorer/show/player', showId],
  showProducts: () => ['GET', '/database-explorer/product'],
  userBuyerOrders: (userId: User['id']) => ['GET', `/database-explorer/order`, 'buyer', userId],
  usersByDeviceId: (deviceId: string) => ['GET', `/bits-data/user-used-device/search`, deviceId],
  userUsedDevicesByUserId: (userId?: number) => [
    'GET',
    `/bits-data/user-used-device/by-user`,
    userId,
  ],
  userDataConsent: (userId: number) => ['GET', '/database-explorer/customer/data-consent', userId],
  userDetail: (userId: number) => ['GET', '/database-explorer/customer', userId],
  userPaymentBalance: (userId: number) => ['GET', `/payment/available-for-transfer/${userId}`],
  userSellerCompanyDetail: (userId: number) => [
    'GET',
    '/database-explorer/seller-company/by-user-id/',
    userId,
  ],
  userSellerConfigDetail: (userId: number) => [
    'GET',
    '/database-explorer/seller-config/user/',
    userId,
  ],
  userSellerOrders: (userId: User['id']) => ['GET', `/database-explorer/order`, 'seller', userId],
  userShippingAddresses: (userId?: number) => [
    'GET',
    '/database-explorer/shipping-address',
    userId,
  ],
  userTargetPromotion: (promotionId?: number) => [
    'GET',
    `/promotions/${promotionId}/user-attached`,
  ],
  userZendeskData: (userId: number) => ['GET', '/database-explorer/customer/zendesk-data', userId],
  orderDetail: (orderId: number) => ['GET', '/database-explorer/order', orderId],
};
