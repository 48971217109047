import { Shipment } from '@bits-app/bits-server-data';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { authenticatedInstance } from '@/axios/axios.instance';
import { QUERIES_KEYS, queryClient } from '@/queries';

import { useSnackbar } from '../../../../components/elements/snackbar/use-snackbar';

const fetchShipment = async (shipmentId: Shipment['id']) => {
  return authenticatedInstance.get<Shipment>(
    `/database-explorer/shipment/${shipmentId}?customer=true&seller=true&shippingAddress=true`,
  );
};

export const useShipmentDetail = (shipmentId: Shipment['id']) => {
  const snackbar = useSnackbar();
  const { isLoading, data: shipment } = useQuery(
    QUERIES_KEYS.getShipment(shipmentId),
    () => fetchShipment(shipmentId),
    {
      onError: (error: AxiosError) => {
        snackbar.error(error.message || 'An error occurred');
      },
    },
  );

  const refetchShipment = () => {
    queryClient.invalidateQueries(QUERIES_KEYS.getShipment(shipmentId));
  };

  return {
    shipment: shipment?.data,
    loading: isLoading,
    refetchShipment,
  };
};
